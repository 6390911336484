














































































import { Component, Vue, Watch } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/HomepageBannerController'
import Button from '@/app/ui/components/Button/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import BannerItemReposition from '../components/BannerItemReposition/index.vue'
import Draggable from 'vuedraggable'
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'
import { HomepageBanner } from '@/domain/entities/HomepageBanner'
import { ErrorMessage } from '@/domain/entities/BaseResponse'

@Component({
  components: {
    Button,
    Modal,
    LoadingOverlay,
    BannerItemReposition,
    Draggable,
  },
})
export default class HomepageBannerRepositionPage extends Vue {
  isGuest = Utils.isGuest()
  controller = controller
  confirmationModal = false

  created(): void {
    this.fetchActiveHomepageBannerList()

    EventBus.$on(
      EventBusConstants.REPOSITION_HOMEPAGE_BANNER_SUCCESS,
      (message: ErrorMessage | null) => {
        Vue.notify({
          title: 'Homepage Banner Reposition',
          text: message?.en || 'Banner is successfully repositioned',
          type: 'success',
          duration: 5000,
        })

        this.$router.push({ name: 'HomepageBannerList' })
      }
    )
  }

  get bannerItems(): HomepageBanner[] {
    return controller.homepageBannerData
  }

  set bannerItems(bannerItems: HomepageBanner[]) {
    controller.setActiveHomepageBannerData(bannerItems)
  }

 @Watch('bannerItems')
  private updatedBannerItems(bannerItems: HomepageBanner[]) {
    if (bannerItems.length === 0) {
      this.$router.push({ name: 'HomepageBannerList' })
    }
  }

  get isLoading(): boolean {
    return controller.isLoading
  }

  private fetchActiveHomepageBannerList(): void {
    this.controller.getActiveHomepageBannerList()
  }

  get errorSaveReposition(): string {
    return controller.errorSaveReposition
  }

  @Watch('errorSaveReposition')
  private goToHomeBannerList() {
    if(this.errorSaveReposition !== '') {
      this.$router.push({ name: 'HomepageBannerList' })
    }
  }

  private saveReposition() {
    this.controller.reposition(this.bannerItems.map(item => Number(item.id)))
  }

  beforeDestroy(): void {
    EventBus.$off(EventBusConstants.REPOSITION_HOMEPAGE_BANNER_SUCCESS)
  }
}
