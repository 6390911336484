














import { HomepageBanner } from '@/domain/entities/HomepageBanner'
import { Prop, Component, Vue } from 'vue-property-decorator'

@Component
export default class BannerItemReposition extends Vue {
  @Prop({ required: true }) private data!: HomepageBanner
}
