import {
  CreateHomepageBannerRequestInterface,
  UpdateHomepageBannerRequestInterface,
  HomepageBannerRepositionRequestInterface,
} from '@/data/payload/contracts/HomepageBannerRequest'

export class CreateHomepageRequest
  implements CreateHomepageBannerRequestInterface {
  public title: string
  public image: Blob | File
  public urlLink: string
  public startTime: number
  public endTime: number

  constructor(
    title: string,
    image: Blob | File,
    urlLink: string,
    startTime: number,
    endTime: number
  ) {
    this.title = title
    this.image = image
    this.urlLink = urlLink
    this.startTime = startTime
    this.endTime = endTime
  }

  public toPayload(): FormData {
    const data = new FormData()
    data.append('title', this.title)
    data.append('image', this.image)
    data.append('url_link', this.urlLink)
    data.append('start_time', String(this.startTime))
    data.append('end_time', String(this.endTime))

    return data
  }
}

export class UpdateHomepageRequest
  implements UpdateHomepageBannerRequestInterface {
  public title: string
  public image: Blob | File | null
  public urlLink: string
  public startTime: number
  public endTime: number

  constructor(
    title: string,
    image: Blob | File | null,
    urlLink: string,
    startTime: number,
    endTime: number
  ) {
    this.title = title
    this.image = image
    this.urlLink = urlLink
    this.startTime = startTime
    this.endTime = endTime
  }

  public toPayload(): FormData {
    const data = new FormData()
    data.append('title', this.title)

    if (this.image) {
      data.append('image', this.image)
    }

    data.append('url_link', this.urlLink)
    data.append('start_time', String(this.startTime))
    data.append('end_time', String(this.endTime))

    return data
  }
}

export class HomepageBannerRepositionRequest
  implements HomepageBannerRepositionRequestInterface {
  public listBannerId: number[]

  constructor(listBannerId: number[]) {
    this.listBannerId = listBannerId
  }

  public toPayload(): string {
    return JSON.stringify({
      list_banner_id: this.listBannerId,
    })
  }
}
