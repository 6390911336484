import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'
import {
  CreateHomepageRequest,
  UpdateHomepageRequest,
  HomepageBannerRepositionRequest,
} from '@/data/payload/api/HomepageBannerRequest'
import { HomepageBannerPresenter } from '@/app/ui/presenters/HomepageBannerPresenter'
import {
  HomepageBanners,
  HomepageBanner,
} from '@/domain/entities/HomepageBanner'
import { Pagination } from '@/domain/entities/Pagination'
import { HOMEPAGE_BANNER_PAGINATION } from '@/app/infrastructures/misc/Constants/pagination'

export interface HomepageBannerState {
  isLoading: boolean
  paginationData: Pagination
  homepageBannerData: HomepageBanner[]
  homepageBannerDetail: HomepageBanner
  totalActiveBanner: number
  errorSaveReposition: string
}

interface Form {
  title: string
  urlLink: string
  startTime: number
  endTime: number
  image: Blob | File | null
}

interface EditForm extends Form {
  id: number
}

@Module({ namespaced: true, store, name: 'homepage-banner', dynamic: true })
class HomepageBannerController extends VuexModule
  implements HomepageBannerState {
  private presenter: HomepageBannerPresenter = container.resolve(
    HomepageBannerPresenter
  )
  public isLoading = false
  public paginationData = new Pagination(1, HOMEPAGE_BANNER_PAGINATION)
  public homepageBannerData: HomepageBanner[] = []
  public homepageBannerDetail = new HomepageBanner()
  public totalActiveBanner = 0
  public errorSaveReposition = ''

  @Action({ rawError: true })
  public createHomepageBanner(form: Form) {
    this.setLoading(true)

    this.presenter
      .create(
        new CreateHomepageRequest(
          form.title,
          form.image as Blob,
          form.urlLink,
          form.startTime,
          form.endTime
        )
      )
      .then(() => {
        EventBus.$emit(EventBusConstants.CREATE_HOMEPAGE_BANNER_SUCCESS, {})
      })
      .catch(error => {
        Vue.notify({
          title: 'Create Homepage Banner Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updateHomepageBanner(form: EditForm) {
    this.setLoading(true)

    this.presenter
      .update(
        form.id,
        new UpdateHomepageRequest(
          form.title,
          form.image,
          form.urlLink,
          form.startTime,
          form.endTime
        )
      )
      .then(() => {
        EventBus.$emit(EventBusConstants.UPDATE_HOMEPAGE_BANNER_SUCCESS, {
          id: form.id,
        })
      })
      .catch(error => {
        Vue.notify({
          title: 'Update Homepage Banner Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public cancelHomepageBanner(homepageBannerId: number) {
    this.setLoading(true)

    this.presenter
      .cancel(homepageBannerId)
      .then(() => {
        EventBus.$emit(EventBusConstants.CANCEL_HOMEPAGE_BANNER_SUCCESS, {
          id: homepageBannerId,
        })
      })
      .catch(error => {
        Vue.notify({
          title: 'Cancel Homepage Banner Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getHomepageBannerList(
    params: Record<string, string | number | undefined>
  ) {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then(res => {
        this.setHomepageBannerData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Homepage Banner Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getActiveHomepageBannerList() {
    this.setLoading(true)

    this.presenter
      .getActiveBanners()
      .then(res => {
        this.setActiveHomepageBannerData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Active Homepage Banner Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getHomepageBannerDetail(id: number) {
    this.setLoading(true)
    this.presenter
      .get(id)
      .then(res => {
        this.setHomepageBannerDetail(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Homepage Banner Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public reposition(bannerIds: number[]) {
    this.setLoading(true)

    this.presenter
      .reposition(new HomepageBannerRepositionRequest(bannerIds))
      .then(message => {
        EventBus.$emit(
          EventBusConstants.REPOSITION_HOMEPAGE_BANNER_SUCCESS,
          message
        )
      })
      .catch(error => {
        const isBadStatus = error.status === 400 || error.status === 422
        this.setErrorSaveReposition(isBadStatus ? error.error.message.en : '')
        Vue.notify({
          title: 'Reposition Homepage Banner Failed',
          text: isBadStatus ? error.error.message.en : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public countActiveBanner() {
    this.presenter
      .countActiveBanner()
      .then(total => {
        this.setTotalActiveBanner(total)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Count Active Banner Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
  }

  @Mutation
  private setHomepageBannerData(homepageBanners: HomepageBanners) {
    this.paginationData = homepageBanners.pagination as Pagination
    this.homepageBannerData = homepageBanners.data as HomepageBanner[]
  }

  @Mutation
  public setActiveHomepageBannerData(homepageBanners: HomepageBanner[]) {
    this.homepageBannerData = homepageBanners
  }

  @Mutation
  private setHomepageBannerDetail(homepageBanner: HomepageBanner) {
    this.homepageBannerDetail = homepageBanner
  }

  @Mutation
  private setLoading(bool: boolean) {
    this.isLoading = bool
  }

  @Mutation
  private setTotalActiveBanner(total: number) {
    this.totalActiveBanner = total
  }

  @Mutation
  private setErrorSaveReposition(msg: string) {
    this.errorSaveReposition = msg
  }
}

export default getModule(HomepageBannerController)
